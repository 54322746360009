<template>
    <div>
        <Navbar page="Profissionais no Projeto" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-2">
                        <div class="grid grid-cols-12 gap-6">
                            <div  class="col-span-12 lg:col-span-3">
                                <label for="email" class="block text-sm font-medium">Email *</label>
                                <input :disabled="form._id" v-model="form.email" type="email" name="email" id="name" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 lg:col-span-3">
                                <label for="nome" class="block text-sm font-medium">Nome *</label>
                                <input :disabled="form._id" v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 lg:col-span-3">
                                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                                <input :disabled="form._id" v-model="form.telefone" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div v-if="form.isDpo" class="col-span-12 lg:col-span-3">
                                <label for="cpfCnpj" class="block text-sm font-medium">CPF / CNPJ</label>
                                <input :disabled="form._id" v-model="form.cpfCnpj" type="text" name="cpfCnpj" id="cpfCnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                        </div>
                    </div>
                    
                    <div v-if="$store.state.user.consultor" class="px-5 pb-4 mt-3">
                        <div class="grid grid-cols-12 gap-6 mt-3">
                            
                            <div class="col-span-12 md:col-span-6 ">
                                <b class="block text-sm">Empresas / Projetos disponíveis</b>

                                <div v-for="item in empresas" :key="item._id" class="border border-gray-200 px-6 py-5">
                                    <div class="grid grid-cols-12 gap-3">
                                        <div class="col-span-12 md:col-span-6">
                                            <img class="h-12 justify-self-center mb-2" v-if="item && item.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${item.logo.filename}&folder=empresas`"/>
                                            <span v-else class="text-base font-semibold mb-2">{{ item.nome }}</span>
                                        </div>
                                        <div class="col-span-12 md:col-span-6 md:mt-1">
                                            <button type="button" @click="autorizarAcesso(item)" class="bg-green-500 text-white p-2 rounded-lg">Autorizar acesso</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <b class="block text-sm">Empresas / Projetos que o profissional tem acesso</b>

                                <div v-for="item in form.empresasFuncoes" :key="item.empresa._id">
                                    <div class="border border-gray-200 px-6 py-5" v-if="temacesso(item.empresa)">
                                        <div class="flex place-items-center justify-between">
                                            <div class="flex place-items-center">
                                                <img class="h-12 justify-self-center mb-2" v-if="item && item.empresa.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${item.empresa.logo.filename}&folder=empresas`"/>
                                                <span class="ml-2 text-base font-semibold mb-2">{{ item.empresa.nome }}</span>
                                            </div>

                                            <label :for="`isDpo_${item._id}`" class="block text-sm font-medium">
                                                <input type="checkbox" class="rounded-sm" :id="`isDpo_${item._id}`" v-model="item.isDpo">
                                                <span class="ml-2">DPO</span>
                                            </label>
                                        </div>
                                        <button @click="removeracesso(item.empresa._id)" type="button" class="bg-red-500 text-white p-2 rounded-lg text-xs mt-2">Remover acesso</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            route: 'profissionais',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                name: '',
                email: '',
                cpfCnpj: '',
                telefone: '',
                password: '',
                empresas: [],
                empresasFuncoes: [],
                funcao: '',
                isDpo: false,
                receberEmail: true,
                foto: null,
            },
            empresas: [],
        }
    },
    methods: {
        removeracesso(empresaId) {
            const filtradas = this.form.empresasFuncoes.filter((e) => e.empresa._id !== empresaId)
            this.form.empresasFuncoes = filtradas
        },
        autorizarAcesso(empresa) {
            console.log('autorizar')

            const jaTem = this.form.empresasFuncoes.find((e) => e.empresa._id === empresa._id)

            const newEmpFunc = {
                empresa,
                isDpo: false,
            }

            if (!jaTem) {
                this.form.empresasFuncoes.push(newEmpFunc)
            } else {
                this.$vToastify.error("Nesta empresa o profissional já possui acesso!", "Erro");
            }
        },
        temacesso(empresaDoProfissional) {
            return this.empresas.map(e => e._id).indexOf(empresaDoProfissional._id) > -1
        },
        async save() {
            const req = await this.$http.put(`/v1/${this.route}/config`, this.form);
            
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                //this.$router.push({ path: '/cadastros/profissionais' });
            }
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listReq = await this.$http.post(`/v1/empresas/list`, { all: true });
        this.empresas = listReq.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>